<template>
  <div class="form-terms">
    <span class="terms-description">{{ $t(descriptionText) }}</span>
    <div class="terms-links">
      <a
        href="https://www.spartan.com/en/about/privacy-policy"
        target="_blank"
        class="terms-link"
        >{{ $t("privacy_policy") }}</a
      >
      <span class="links-divider">{{ $t("and") }}</span>
      <a
        href="https://www.spartan.com/en/about/terms-of-service"
        target="_blank"
        class="terms-link"
        >{{ $t("terms_of_use") }}.</a
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    descriptionText: {
      type: String,
      default: "account_terms_line",
    },
  },
};
</script>
<style lang="scss" scoped>
.form-terms {
  // margin-bottom: 48px;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.4;
  @media screen and (max-width: 499px) {
    // margin-bottom: 44px;
  }
  .terms-description {
    margin-right: 5px;
  }
  .terms-links {
    .links-divider {
      margin: 0 5px;
    }
    .terms-link {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 2px;
      cursor: pointer;
      outline: none;
    }
  }
}
</style>